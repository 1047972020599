import CustomSpinner from 'components/Misc/CustomSpinner';
import { MainContext } from 'context/mainContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { groupDashboardsBI, groupDashboardTemplate, groupDashboards, getCustomGroups } from 'services/mongoDB';
import { useHistory } from "react-router";

function DashboardListComponent({ selectedDashboardTemplate, userTemplateList, handleSelectTemplate, onToggleShowDashboardOption, toggleShowDashboardOption, 
                                    handleAddtoFavorite, handleNoDashboard, handleSetClientUsers, handleSetClientGroups,
                                    handleGroupDashboardTemplatesList, dashboardUpdate }) 
{
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID, selectedClient } = useContext(MainContext);
    const [groupDashboardList, setGroupDashboardList] = useState(null)
    const [dashboardTemplatesList, setDashboardTemplateList] = useState([])
    const [groupList, setGroupList] = useState(null)
    const [loading, setLoading] = useState(true);
    const [groupAllDashboardList, setGroupAllDashboardList] = useState([])
    const [isMultiFavorite, setIsMultiFavorite] = useState(false)
    const [favoriteDashboard, setFavoriteDashboard] = useState(null)
    const [favoriteDashboardUpdate, setFavoriteDashboardUpdate] = useState(0)
    const [withPersonalDashboardFavorite, setWithPersonalDashboardFavorite] = useState(true)

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
    
        const loadData = async () => {
            if (!isMounted) return;
    
            try {
                const param = {
                    'ClientId': selectedClientID,
                    'email': userDetails.User.Email || ''
                };
    
                const res = await getCustomGroups(param);
                handleSetClientGroups(res);
    
                const users = selectedClient?.User?.map(item => item.Email) || [];
                const groups = [{
                    "id": selectedClient.ClientID,
                    "Users": users,
                    "GroupName": selectedClient.Name,
                    "ClientId": selectedClient.ClientID
                }];
    
                const myGroups = [...groups, ...(res || [])];
    
                const TemplateParam = { clientId: selectedClientID };
                const [groupDashboardsData, groupDashboardsBIData, templateRes] = await Promise.all([
                    groupDashboards(TemplateParam),
                    groupDashboardsBI(TemplateParam),
                    groupDashboardTemplate(TemplateParam)
                ]);
    
                const formattedBIData = groupDashboardsBIData?.map(item => ({ ...item, isBI: true })) || [];
                const groupDashboardTemplatesList = templateRes?.filter(item => !item.isPersonalDashboard && !item.isSoftDelete) || [];
    
                const allGroupDashboard = [
                    ...(groupDashboardsData || []),
                    ...formattedBIData,
                    ...groupDashboardTemplatesList
                ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
                const groupDashboardList = buildGroupDashboardList(myGroups, allGroupDashboard);
                const withPersonalDashboardFavorite = userTemplateList.some(template => template.isFavorite);
                const withGroupDashboard = checkAndSelectGroupDashboard(groupDashboardList, withPersonalDashboardFavorite);

                setWithPersonalDashboardFavorite(withPersonalDashboardFavorite);
    
                if (!withPersonalDashboardFavorite) {
                    selectFirstFavoriteDashboard(groupDashboardList, userTemplateList);
                    processMultiFavoriteDashboard(groupDashboardList);
                }

                setGroupAllDashboardList(groupDashboardList);
                handleGroupDashboardTemplatesList(groupDashboardList.flatMap(group => group.dashboardList));
    
                handleNoDashboard(!(userTemplateList.length > 0 || withGroupDashboard));
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoading(false);
            }
        };
    
        loadData();
    
        return () => { isMounted = false; };
    }, [dashboardUpdate, favoriteDashboardUpdate]);
    
    // Helper Functions
    
    const buildGroupDashboardList = (groups, dashboards) => {
        return groups.map(group => {
            const groupId = String(group.id);
            const dashboardsList = dashboards.filter(dashboard =>
                (dashboard.isBI && String(dashboard.groupId) === groupId) ||
                dashboard.selectedGroupId?.groupsId.some(id => String(id) === groupId)
            );
            return {
                groupId,
                groupName: group.GroupName,
                dashboardList: dashboardsList
            };
        });
    };
    
    const selectFirstFavoriteDashboard = (groupDashboardList) => {
        const isWithFavoriteGroupDashboard = groupDashboardList.some(group => {
            if (group.dashboardList.length > 0) {
                const favoriteIndex = group.dashboardList.findIndex(dashboard => dashboard.isFavorite);
                if (favoriteIndex !== -1) {
                    handleSelectTemplate(group.dashboardList[favoriteIndex], favoriteIndex);
                    return true;
                }
            }
            return false;
        });

  
        if (!isWithFavoriteGroupDashboard && userTemplateList.length === 0 && groupDashboardList && groupDashboardList.length > 0) {
            for (let index = 0; index < groupDashboardList.length; index++) {
                const group = groupDashboardList[index];
                if (group.dashboardList.length > 0) {
                    for (let dashIndex = 0; dashIndex < group.dashboardList.length; dashIndex++) {
                        const dashboard = group.dashboardList[dashIndex];
                        handleSelectTemplate(dashboard, dashIndex + index);
                        break;
                    }
                    break;
                }
            }
        }        

        if(!isWithFavoriteGroupDashboard && userTemplateList.length > 0) handleSelectTemplate(userTemplateList[0], 0);
    };
    
    const checkAndSelectGroupDashboard = (groupDashboardList) => {
        for (const group of groupDashboardList) {
            if (group.dashboardList.length > 0) {
                if(!withPersonalDashboardFavorite) handleSelectTemplate(group.dashboardList[0], 0);
                return true;
            }
        }
        return false;
    };
    
    const processMultiFavoriteDashboard = (groupDashboardList) => {
        const favoriteCount = groupDashboardList.reduce((count, group) => 
            count + group.dashboardList.filter(dashboard => dashboard.isFavorite).length, 0
        );
    
        setIsMultiFavorite(favoriteCount > 1);
    
        const param = { id: userDetails.User.id };
        fetch(`/api/user/getUserDetailsById/${userDetails.User.id}`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(param),
        })
        .then(res => res.json())
        .then(data => {
            const details = data[0]?.Details;
            const favDashboard = details?.favoriteGroupDashboard?.find(d => d.clientId === selectedClientID);
            setFavoriteDashboard(favDashboard || null);
        })
        .catch(err => console.log(err));
    };
    
    useEffect(() => {
        if (!favoriteDashboard) return;
        if (withPersonalDashboardFavorite) return
        const group = groupAllDashboardList.find(d => String(d.groupId) === favoriteDashboard.groupId);
        group?.dashboardList.forEach((dashboard, index) => {
            if (dashboard.isFavorite) handleSelectTemplate(dashboard, index);
        });
    }, [favoriteDashboard, withPersonalDashboardFavorite]);
    
    const handleClick = (item, index) => {
        handleSelectTemplate(item, index);
        onToggleShowDashboardOption();
    };
    
    const onHandleSetFavorite = (index, isPersonalDashboard = true, groupIndex, groupId) => {
        const group = groupAllDashboardList.find(e => String(e.groupId) === String(groupId));
        if (!isPersonalDashboard && isMultiFavorite && group?.dashboardList.length > 0) {
            const dashboard = group.dashboardList[index];
            const isFavoriteMatch = favoriteDashboard?.groupId === group.groupId && dashboard.id === favoriteDashboard.dashboardId;
    
            if (isFavoriteMatch) {
                handleAddtoFavorite({ index, userTemplateList, groupAllDashboardList, from: 'group', groupIndex, groupId });
                processMostFavoriteDashboard(dashboard, groupId);
            } else if (dashboard.isFavorite) {
                processMostFavoriteDashboard(dashboard, groupId);
            } else {
                handleAddtoFavorite({ index, userTemplateList, groupAllDashboardList, from: 'group', groupIndex, groupId });
            }
        } else {
            handleAddtoFavorite({ index, userTemplateList, groupAllDashboardList, from: isPersonalDashboard ? 'personal' : 'group', groupIndex, groupId });
        }
    };
    
    const processMostFavoriteDashboard = (dashboard, groupId) => {
        setFavoriteDashboardUpdate(prev => prev + 1);
        const param = {
            clientId: selectedClientID,
            dashboardId: dashboard.id,
            groupId,
            isBI: dashboard.isBI || false
        };
        fetch(`/api/user/updateUserDetails/${userDetails.User.id}`, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(param),
        })
        .then(res => res.json())
        .then(() => setFavoriteDashboardUpdate(prev => prev + 1))
        .catch(err => console.log(err));
    };    

    const history = useHistory();
    const toggleTemplateModal = () => {
        // setIsTemplateEdit(false)
        sessionStorage.setItem("selectedTemplatetoEdit", null);
        // setShowTemplateModal(!showTemplateModal)
        return history.push(`/client/templates`);
    }
    return (
        <>
        <div
            style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
                zIndex: 9998
            }}
            onClick={toggleShowDashboardOption}
        />

        <div className="dashboardContainer">
            <div className="dashboardBody">
            <Row>
                {loading ? <CustomSpinner /> : 
                <>
                    <Col lg='6' sm='12'>
                        <h2>Personal Dashboard</h2>
                        {userTemplateList && userTemplateList.length > 0 ? 
                            userTemplateList.map((item, index) => (
                                <div 
                                    key={item.id} 
                                    className="dashboardOption" 
                                    style={selectedDashboardTemplate?.id === item.id ? { color: bgColor, background: '#fafafa' } : {}}
                                >
                                    <div className='dashboardOptionFav' onClick={e => onHandleSetFavorite(index)}>
                                        <i 
                                            style={{ color: !item.isFavorite && '#b0b0b0' }}
                                            className={`${!item.isFavorite ? 'far' : 'fas'} fa-star fa-sm`}>    
                                        </i>
                                    </div>
                                    <div  style={{ display: 'flex', flexDirection: 'row', width: '100%' }} onClick={() => { handleClick(item, index) }}>
                                        <div className="dashboardImg">
                                            <img
                                                alt="..."
                                                className="mr-2"
                                                style={{ height: "25px", width: "25px" }}
                                                src={item.isBI ? require("assets/img/bi.png").default : require("assets/img/etanilogo.png").default}
                                            />
                                        </div>
                                        <div className="dashboardName">{item.name}</div>
                                    </div>
                                </div>
                            ))
                        :
                            <>
                                <div style={{ padding: 10, fontSize: 12 }}>No dashboard Found!</div>
                            </>
                        }
                    </Col>
                    <Col lg='6' sm='12'>
                        <h2>Group Dashboard</h2>
                        {groupAllDashboardList && groupAllDashboardList.map((item, index) => (
                            <div key={item.groupId}>
                            <h5>{item.groupName}</h5>
                            {item.dashboardList && item.dashboardList.length > 0 ?
                            <>
                                {item.dashboardList.map((DashboardItem, dashboardIndex) => (
                                    <div key={`${DashboardItem.id}-${dashboardIndex}`} 
                                        className="dashboardOption" 
                                        onClick={() => {
                                                handleSelectTemplate(DashboardItem, dashboardIndex);
                                                onToggleShowDashboardOption()
                                            }}
                                        style={selectedDashboardTemplate?.id === DashboardItem.id ? {  color: bgColor, background: '#fafafa' } : {}}
                                    >
                                        <div
                                            className='dashboardOptionFav' 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onHandleSetFavorite(dashboardIndex, false, index, item.groupId);
                                            }}
                                        >
                                            <i 
                                                // style={{ color: !DashboardItem.isFavorite && '#b0b0b0' }}
                                                style={{ color: !DashboardItem.isFavorite ? '#b0b0b0' : 
                                                favoriteDashboard && 
                                                DashboardItem.id === favoriteDashboard.dashboardId && 
                                                item.groupId === favoriteDashboard.groupId &&
                                                '#f6d95b' }}
                                                className={`${!DashboardItem.isFavorite ? 'far' : 'fas'} fa-star fa-sm`}>    
                                            </i>
                                        </div>
                                        <div className="dashboardImg">
                                            <img
                                                alt="..."
                                                className="mr-2"
                                                style={{ height: "25px", width: "25px" }}
                                                src={DashboardItem.isBI ? require("assets/img/bi.png").default : require("assets/img/etanilogo.png").default}
                                            />
                                        </div>
                                        <div className="dashboardName">{DashboardItem.name}</div>
                                    </div>
                                ))}
                            </> 
                            :
                            <>
                                <div style={{ padding: 10, fontSize: 12 }}>No dashboard Found!</div>
                            </>
                            }
                            </div>
                        ))}
                    </Col>
                </>
                }
            </Row>
            </div>
            <div className="dashboardFooter" style={{ color: bgColor }} onClick={toggleTemplateModal}>
                Create Dashboard Templates
            </div>
        </div>
        </>
    );
}

export default DashboardListComponent;
