import React, { useRef } from 'react'
import _, { forEach } from "lodash";
import { useState, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import DashboardTemplateItemNew from './DashboardTemplateItemNew';
import { useContext } from 'react';
import { MainContext } from 'context/mainContext';
import { updateVisualPersonalDashboard } from 'services/mongoDB';
import { registerDashboardTemplate } from 'services/mongoDB';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { updateVisualUserDashboard } from 'services/mongoDB';
import { updateVisualGroupDashboard } from 'services/mongoDB';

const WidthResponsive = WidthProvider(Responsive);

function DashboardItemDnD({selectedDashboardTemplate, selectedTemplate, handleShowDashboardList, pinToDashboardUpdates, handleIsDimensionChange, isEditIndex, ...props}) {
    const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
    const [compactType, setCompactType] = useState("vertical");
    const [mounted, setMounted] = useState(false);
    const [toolbox, setToolbox] = useState({});
    const [layouts, setLayouts] = useState({});
    const [prevLayouts, setPrevLayouts] = useState({});
    const [isEnabledDnD, setIsEnalbeDnD] = useState(true)
    const [isDimensionChange, setIsDimensionChange] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const { 
        bgColor, 
        textColor
    } = useContext(MainContext);

    const [alert, setAlert] = useState(false);
    const [onEditName, setOnEditName] = useState(false)
    const [onFullScreenOnly, setOnFullScreenOnly] = useState(false)
    const isInitialMount = useRef(true)

    useEffect(() => {
        // if(onEditName) props.handleSetDnDLayout(layouts)
        // else props.handleSetDnDLayout(null)
        // console.log(props.DnDLayout)
       
        handleIsDimensionChange(isDimensionChange)
    },[props.DnDLayout, isDimensionChange])

    useEffect(() => {
        if(!props.editMode) return
        // console.log(prevLayouts)

        const isEmptyObject = (value) => {
            return typeof value === 'object' && value !== null && !Array.isArray(value) && Object.keys(value).length === 0;
        };
        
        if (isEmptyObject(prevLayouts)) return;

        setIsDimensionChange(areLayoutsEqual(prevLayouts, layouts.lg))
    },[layouts])

    function areLayoutsEqual(prevLayout, newLayout) {
        // console.log({ prevLayout, newLayout })
        let changes = 0
        prevLayout && prevLayout.map((item, i) => {
            if(!newLayout[i]) return
            
            if(item.x !== newLayout[i].x)
                 changes++
            if(item.y !== newLayout[i].y) 
                changes++
            if(item.h !== newLayout[i].h) 
                changes++
            if(item.w !== newLayout[i].w) 
                changes++
        })
        // console.log(changes)
        return changes > 0;
    }
    
    useEffect(() => {
        // setIsEnalbeDnD(!props.editMode)
        if(!props.editMode) {
            props.handleSetDnDLayout(null)
            props.handleUpdateLayout()
        }else {
            const prevLayout = selectedTemplate.map((item, i) => {
                return { 
                    x: item.visual && item.visual.x !== undefined ? item.visual.x : 0,
                    y: item.visual && item.visual.y !== undefined ? item.visual.y : (item.visual && item.visual.y > 0 ? i * 14 : 0),
                    w: item.visual && item.visual.width !== undefined ? item.visual.width : 5,
                    h: item.visual && item.visual.height !== undefined ? item.visual.height : 14,
                    i: i.toString(),
                }
            })
            setPrevLayouts(prevLayout)
        }
    },[props.editMode])

    useEffect(() => 
    {

    },[selectedTemplate])

    useEffect(() => {
        
        // if(props.DnDLayout && props.editMode) {
        //     console.log(props.DnDLayout)
        //     setLayouts(props.DnDLayout)
        //     return
        // }
        // console.log(selectedDashboardTemplate)
        if (selectedTemplate && selectedTemplate.length > 0) 
        {
            const item = {
                lg: selectedTemplate.map(function (item, i) 
                {
                    if(props.updatedLayout)
                        return {
                            x: props.updatedLayout[i].x,
                            y: props.updatedLayout[i].y,
                            w: props.updatedLayout[i].w,
                            h: props.updatedLayout[i].h,
                            i: i.toString(),
                            static: !props.editMode || !isEnabledDnD
                        };
                    else
                        return {
                            x: item.visual && item.visual.x !== undefined ? item.visual.x : 0,
                            y: item.visual && item.visual.y !== undefined ? item.visual.y : (item.visual && item.visual.y > 0 ? i * 14 : 0),
                            w: item.visual && item.visual.width !== undefined ? item.visual.width : 5,
                            h: item.visual && item.visual.height !== undefined ? item.visual.height : 14,
                            i: i.toString(),
                            static: !props.editMode || !isEnabledDnD
                        };
                })
            };
            setLayouts(item);
        }
    }, [selectedTemplate, props.editMode, isEnabledDnD, pinToDashboardUpdates, props.showEVA]);
    
    useEffect(() => {
        setMounted(true);
    }, []);

    const onBreakpointChange = (breakpoint) => {
        setCurrentBreakpoint(breakpoint);
        setToolbox({
            ...toolbox,
            [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
        });
    };

    const onLayoutChange = (layout, layoutVal) => {
        props.handleSetDnDLayout(layouts)
        setLayouts({ ...layoutVal });
    };

    const onDrop = (layout, layoutItem, _ev) => {
        // console.log(`Element parameters:\n${JSON.stringify(layoutItem, ["x", "y", "w", "h"], 2)}`);
    };

    const handleSaveDimensions = async () => 
    {
        if(selectedTemplate.length > 0)
        {
            try{
                
                selectedTemplate.forEach((item, index) => 
                {
                    if(item.visual === undefined || item.visual === null)
                        item.visual = {}

                    if (layouts && layouts.lg && layouts.lg[index]) {
                        item.visual.width = layouts.lg[index].w
                        item.visual.height = layouts.lg[index].h
                        item.visual.x = layouts.lg[index].x
                        item.visual.y = layouts.lg[index].y
                        if(item.selectedViewOption === undefined) item.selectedViewOption = 'Grid'
                    }
                })

                if(selectedDashboardTemplate.personalDashboards) updateVisualPersonalDashboard(selectedDashboardTemplate);
                else if (selectedDashboardTemplate.userDashboard) updateVisualUserDashboard(selectedDashboardTemplate);
                else if (selectedDashboardTemplate.details) updateVisualGroupDashboard(selectedDashboardTemplate);
                else registerDashboardTemplate(selectedDashboardTemplate);
                
                const prevLayout = selectedTemplate.map((item, i) => {
                    return { 
                        x: item.visual && item.visual.x !== undefined ? item.visual.x : 0,
                        y: item.visual && item.visual.y !== undefined ? item.visual.y : (item.visual && item.visual.y > 0 ? i * 14 : 0),
                        w: item.visual && item.visual.width !== undefined ? item.visual.width : 5,
                        h: item.visual && item.visual.height !== undefined ? item.visual.height : 14,
                        i: i.toString(),
                    }
                })
                setPrevLayouts(prevLayout)

                handleIsDimensionChange(false)
                setIsDimensionChange(false)

                setAlert(
                    <ReactBSAlert
                        success
                        title="Success"
                        onConfirm={async () => setAlert(null)}
                        confirmBtnBsStyle="success"
                        confirmBtnText="Ok"
                        btnSize=""
                    >
                        <>
                            <p>Changes have been saved successfully.</p>
                        </>
                    </ReactBSAlert>
                );
            }catch(error)
            {
                console.log(error)
                setAlert(
                    <ReactBSAlert
                        error
                        title="Error"
                        onConfirm={() => setAlert(null)}
                        confirmBtnBsStyle="danger"
                        confirmBtnText="Ok"
                        btnSize=""
                    >
                        <>
                            <p>Unable to save changes. Please try again later.</p>
                        </>
                    </ReactBSAlert>
                );
            }
        }
    };

    useEffect(() => {
        // console.log(onEditName)
    },[onEditName])

    useEffect(() => {
        setOnEditName(isEditIndex >= 0)
    },[isEditIndex])

    const hanldeFullScreenOnly = (value) => {
        setOnFullScreenOnly(value)
    }

    const onHandleUpdate = () => {
        // console.log({...props.DnDLayout})
        // props.handleSetDnDLayout(layouts)
        props.handleNewUpdate()
    }

    const handleIsEnalbeDnD = () => {
        setIsEnalbeDnD(!isEnabledDnD)
    }

    const handleDeleteDashboardTemplateItem = (item) => {
        const lastLayout = {...layouts.lg}
        props.handleDeleteDashboardTemplateItem(item, layouts.lg)
    }

    const generateDOM = () => {
        return (
            selectedTemplate && selectedTemplate.length > 0 && selectedTemplate.map((item, index) => (
                <div key={index} 
                    // className={"static"}
                    style={{ 
                        background: !props.editMode ? "#fff" : "#fff", 
                        zIndex: 999,
                        width: screenWidth - 15
                    }}>
                    <DashboardTemplateItemNew
                        key={index}
                        editMode={props.editMode}
                        showEVA={props.showEVA}
                        onResizeItem={props.onResizeItem}
                        isEditIndex={isEditIndex}
                        handleSetItemIndexToEdit={props.handleSetItemIndexToEdit}
                        selectedDashboardTemplate={selectedDashboardTemplate}
                        selectedTemplate={selectedTemplate}
                        item={item}
                        index={index}
                        handleNewUpdate={onHandleUpdate}
                        handleDeleteDashboardTemplateItem={handleDeleteDashboardTemplateItem}
                        layouts={layouts}
                        hanldeFullScreenOnly={hanldeFullScreenOnly}
                        onFullScreenOnly={onFullScreenOnly}
                        tempLayout={props.DnDLayout?.lg}
                        isEnabledDnD={isEnabledDnD}
                    />
                </div>
            ))
        );
    };
    
    return (
        <>
            {alert}
            {props.editMode && 
                <div className="dimensionOptionContainer">
                    <div className="dimensionOptionContent">
                        
                        <div 
                            onClick={handleShowDashboardList} 
                            style={{ backgroundColor: bgColor, color: textColor, width: 'auto' }}
                        >
                            {!props.showDashboardList ? "Show" : "Hide"} Dashboard List
                        </div>
                        <div>
                            {isDimensionChange &&
                            <>
                                {isEnabledDnD &&
                                    <span 
                                        onClick={handleSaveDimensions} 
                                        style={{ backgroundColor: bgColor, color: textColor }}
                                    >
                                        Save Changes
                                    </span>
                                }
                            </>
                            }
                        </div>
                        
                    </div>
                </div>
            }

            {(onEditName || onFullScreenOnly) ?
                <>
                    <div className='dashboardTemplateItemContainer' style={{ display: 'block', maxHeight: 'none'}}>
                        {selectedTemplate.map((item, index) => (
                            <DashboardTemplateItemNew
                                key={index}
                                isEnabledDnD={true}
                                editMode={props.editMode}
                                onResizeItem={props.onResizeItem}
                                isEditIndex={isEditIndex}
                                handleSetItemIndexToEdit={props.handleSetItemIndexToEdit}
                                selectedDashboardTemplate={selectedDashboardTemplate}
                                selectedTemplate={selectedTemplate}
                                item={item}
                                index={index}
                                handleNewUpdate={onHandleUpdate}
                                handleDeleteDashboardTemplateItem={handleDeleteDashboardTemplateItem}
                                layouts={layouts}
                                hanldeFullScreenOnly={hanldeFullScreenOnly}
                                onFullScreenOnly={onFullScreenOnly}
                                tempLayout={props.DnDLayout?.lg}
                            />
                        ))}
                    </div>
                </>
            :
                <>
                    <div className={`mb-4 ${props.editMode ? 'isEdit' : 'isNotEdit'}`} style={{ width: props.editMode ? screenWidth - 10 : screenWidth }}>
                        <WidthResponsive
                            {...props}
                            style={{ background: props.editMode ? "#f4f4f4" : "#fff", padding: 10 }}
                            layouts={layouts}
                            measureBeforeMount={false}
                            useCSSTransforms={mounted}
                            compactType={compactType}
                            preventCollision={!compactType}
                            onLayoutChange={onLayoutChange}
                            onBreakpointChange={onBreakpointChange}
                            onDrop={onDrop}
                            isDroppable={props.editMode && isEnabledDnD}
                        >
                            {generateDOM()}
                        </WidthResponsive>
                    </div>
                </>
            }
        </>
    );
}

export default DashboardItemDnD

DashboardItemDnD.defaultProps = {
    className: "layout",
    rowHeight: 30,
    onLayoutChange: (layout, layouts) => {},
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    containerPadding: [0, 0],
  };