import React, { useContext, useEffect, useState } from "react";
import {
    Container,
    Row,
    Card,
    CardHeader,
    Col,
    CardBody,
    Button,
} from "reactstrap";
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import { personalDashboards } from "services/mongoDB";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { deletePersonalDashboard } from "services/mongoDB";
import { updatePersonalDashboard } from "services/mongoDB";
import TabbedView from "components/CustomDashboardDetails/TabbedView";
import { personalDashboardsBI } from "services/mongoDB";
import { deletePersonalDashboardBI } from "services/mongoDB";
import PersonalDashboardList from "components/CustomDashboardDetails/PersonalDashboardList";
import CustomDashboardPanelCard from "components/Cards/CustomDashboardPanelCard";
import CustomDashboardDetails from "components/CustomDashboardDetails/CustomDashboardDetails";
import ReportView from "components/CustomDashboardDetails/ReportView";
import CustomSpinner from "components/Misc/CustomSpinner";
import { getCustomGroups } from "services/mongoDB";
import { userDashboard } from "services/mongoDB";
import { userDashboardBI } from "services/mongoDB";
import { deleteUserDashboard } from "services/mongoDB";
import { deleteUserDashboardBI } from "services/mongoDB";
import { updateUserDashboard } from "services/mongoDB";
import EVA from "eva-component/EVA";
import ChatForum from "../chat/ChatForum";
import Chat from "chat-components/Chat";
import { generateUUID } from "services/EVAResponseService";
import { mongoFetchToken } from "services/mongoDB";
import SendMultiDeviceNotification from "services/NotificationService";
import SendExpoNotifications from "services/ExpoNotificationService";
import DashboardTemplate from "./DashboardTemplate";
import { userDashboardTemplate } from "services/mongoDB";
import DashboardTemplateItem from "./DashboardTemplateItem";
import DashboardTemplateList from "./DashboardTemplateList";
import { deleteUserDashboardTemplate } from "services/mongoDB";
import { registerDashboardTemplate } from "services/mongoDB";
import DashboardTemplateItemNew from "./DashboardTemplateItemNew";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { evaThemeLighter } from "services/EVAResponseService";
import { IconSort } from "eva-component/ui/icon";
import { updateIsFavoritePersonalDashboard } from "services/mongoDB";
import { updateTemplateIsFavorite } from "services/mongoDB";
import DashboardItemDnD from "./DashboardItemDnD";
import EVADashboard from "eva-component/EVADashboard";
import context from "react-bootstrap/esm/AccordionContext";
import DashboardListComponent from "./DashboardListComponent";
import NoDashboardFound from "./NoDashboardFound";
import GroupDashboardTemplateList from "./GroupDashboardTemplateList";
import AllDashboard from "./AllDashboard";
import DashboardUserWithAccess from "./DashboardUserWithAccess";
import { deleteGroupDashboardBI } from "services/mongoDB";
import { updateDashboardTemplateBusinessId } from "services/mongoDB";
import { etaniDashboardTemplates } from "services/mongoDB";
import { groupDashboardTemplate } from "services/mongoDB";
import { updateGroupDashboardIsFavorite } from "services/mongoDB";
// import socket from "services/MainSocket";

function PersonalDashboard({evaIframeUrl, noEvaAccess, editMode, showDashboardOption, onToggleShowDashboardOption, onHandleSelectTemplate, showEVA, showChat, handleStillLoading, isChangingClient, handleIsDimensionChange}) {
    // const [editMode, setEditMode] = useState(false)
    const [dashboardItems, setDashboardItems] = useState([])
    const [selectedDashboardName, setSelectedDashboardName] = useState('')
    const [selectedDashboard, setSelectedDashboard] = useState(null)
    const [activeDashboard, setActiveDashboard] = useState(null)
    const [activeTemplate, setActiveTemplate] = useState(null)
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(false);
    const history = useHistory();
    const [newUpdateCount, setNewUpdateCount] = useState(0);
    const [selectedReportData, setSelectedReportData] = useState(null)
    const [showReport, setShowReport] = useState(false)
    const [dashboardList, setDashboardList] = useState([])
    const [groupList, setGroupList] = useState(null)
    const [userTemplateList, setUserTemplateList] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState([])
    const [selectedDashboardTemplate, setSelectedDashboardTemplate] = useState(null)
    const [itemIndexToEdit, setItemIndexToEdit] = useState(-1)
    const [onResizeItem, setOnResizeItem] = useState(false)
    const [dashboardUpdate, setDashboardUpdate] = useState(0)
    const [pinToDashboardUpdates, setPinToDashboardUpdates] = useState(0)
    const [userAllDashboardList , setUserAllDashboardList] = useState([])
    const [noDashboardFound, setNoDashboardFound] = useState(false)
    const [showDashboardList, setShowDashboardList] = useState(false)
    const [DnDLayout, setDnDLayout] = useState(null);
    const [clientUsers, setClientUsers] = useState(null)
    const [clientGroups, setClientGroup] = useState(null)
    const [dashboardUserWithAccess, setDashboardUserWithAccess] = useState(null)
    const [groupDashboardTemplatesList, setGroupDashboardTemplatesList] = useState([])
    const [evaChatWidth, setEVAChatWidth] = useState(0)
    const { 
        bgColor, 
        textColor, 
        userDetails, 
        firmDetails, 
        selectedClientID, 
        selectedClient, 
        setIsTemplateEdit, 
        setSelectedTemplatetoEdit,
        selectClientIntegration
    } = useContext(MainContext);
    const theme = { PrimaryColor: bgColor, TextColor: textColor }

    useEffect(() => {
        checkEvaAccess()
        if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
            history.push(`/`);
            return;
        }

        let isMounted = true;

        const loadData = async () => {
            try {
                setLoading(true);
                handleStillLoading(true);
                resetState();

                if (Object.keys(userDetails).length === 0) return;
                await fetchMyGroups();

                const dashboardList = await fetchDashboards(userDetails);
                const dashboardTemplates = await fetchUserDashboardTemplate();
                // console.log(dashboardTemplates)
                // if(dashboardTemplates.length > 0) await updateDashboardTemplateBusiness()
                processUserAllDashboardList(dashboardList, dashboardTemplates)
                

            } catch (error) {
                console.error("Error loading data: ", error);
            } finally {
                setLoading(false);
                handleStillLoading(false);
            }
        }

        if (isMounted && !editMode) loadData();
            
        return () => {
            isMounted = false;
        }
    }, [selectedClientID, isChangingClient, newUpdateCount, dashboardUpdate]);

    const [loadCount, setLoadCount] = useState(0);
    useEffect(() => {

        const loadData = async() => {
            let updateCount = 0

            if((loadCount < 1 && selectClientIntegration && selectClientIntegration.length > 0))
            {
                setLoadCount(1)

                const etaniTemplate = await etaniDashboardTemplates()
                let userTemplateList = await fetchGroupDashboardTemplate()
              
                etaniTemplate && etaniTemplate.map( async (item) => {
                   
                    const itemIntegration = item.name.split("-")[1]
                    const dashboardName = item.name.split("-")[0]
                    const templateId = item.uid
                    
                    const isHaveIntegration = selectClientIntegration.find(i => i.integration === itemIntegration)

                    if(isHaveIntegration)
                    {
                        const integrationDate = new Date(isHaveIntegration.createdAt);
                        const itemDate = new Date(item.createdAt);

                        if (integrationDate > itemDate) {

                            const param = { ...item };
                            param.businessId = firmDetails.AccountingFirmID;
                            param.clientId = selectedClientID;
                            param.isEtaniTemplate = false;
                            param.name = dashboardName;
                            param.user = userDetails.User.Email;
                            param.isPersonalDashboard = false
                            param.selectedGroupId.groupsId.push(selectedClientID)
                            
                            const isEtaniTemplateExist = userTemplateList.findIndex(t => t.uid === param.uid);

                            if (isEtaniTemplateExist === -1 && loadCount === 0) {
                                // userTemplateList.push(param)
                                updateCount++
                                // await registerDashboardTemplate(param)
                            }
                        }
                    }
                })

                if(updateCount > 0) setNewUpdateCount(prev => prev + 1)
            }
        }

        loadData();
    },[selectClientIntegration, loadCount, selectedClientID])

    const fetchGroupDashboardTemplate = async () => {
        const param = {
            clientId: selectedClientID,
        };

        const res = await groupDashboardTemplate(param);
        const newRes = res.filter(item => !item.isPersonalDashboard);

        return newRes
    }
    const checkEvaAccess = () => {
        if(!selectedClient.Details.ShowEvaPage) firmDetails.PricingPlan.Code === 'TIER6' ? history.push(`/client/accountant/details`) : history.push(`/client/reports`) ;
    }
    const resetState = () => {
        setUserTemplateList([]);
        setDashboardList([]);
        setSelectedDashboardTemplate(null);
        onHandleSelectTemplate(null);
        setUserAllDashboardList([]);
        setSelectedReportData(null);
        setShowReport(false);
        setSelectedDashboard(null);
        setDashboardItems([]);
        setActiveDashboard(null);
        setSelectedTemplate([]);
        setActiveTemplate(null);
        setIsTemplateEdit(false);
    }
    const fetchDashboards = async (userDetails) => {
        const param = {
            'clientId': selectedClientID,
            'userId': userDetails.User.UserID
        };
    
        try {
            const [res, resBI, groupData] = await Promise.all([
                personalDashboards(param),
                personalDashboardsBI(param),
                fetchMyGroups()
            ]);
    
            const userDashboardRes = groupData?.userDashboardRes || [];
            const userDashboardBIRes = groupData?.userDashboardBIRes || [];
    
            const combinedArray = [
                ...(res || []).map(item => ({ ...item, isBI: false, personalDashboards: true })),
                ...(resBI || []).map(item => ({ ...item, isBI: true, personalDashboardsBI: true })),
                ...userDashboardRes.map(item => ({ ...item, isBI: false, userDashboard: true })),
                ...userDashboardBIRes.map(item => ({ ...item, isBI: true, userDashboardBI: true }))
            ];
    
            combinedArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
            // const favoriteDashboard = combinedArray.find(dashboard => dashboard.isFavorite);
            // if (favoriteDashboard) handleSelectTemplate(favoriteDashboard, combinedArray.indexOf(favoriteDashboard));
            
            setDashboardList(combinedArray);
            return combinedArray

        } catch (error) {
            console.error("Error fetching dashboards: ", error);
        }
    };
    const fetchMyGroups = async () => {
        const param = {
            'ClientId': selectedClientID,
            'email': userDetails.User.Email
        };
        const groups = await getCustomGroups(param);
        let formatedGroupList = []
        if(groups)
            formatedGroupList = groups.map((item) => ({ ...item, id: item._id || item.id }))

        setGroupList(groups);
        return groups;
    }
    const fetchUserDashboardTemplate = async () => {
        const param = {
            clientId: selectedClientID,
            groupId: selectedClientID,
            user: userDetails.User.Email
        };

        const res = await userDashboardTemplate(param);
        const newRes = res.filter(item => item.isPersonalDashboard);
        // const favoriteDashboard = newRes.find(dashboard => dashboard.isFavorite);
        // if (favoriteDashboard) handleSelectTemplate(favoriteDashboard, newRes.indexOf(favoriteDashboard));

        setUserTemplateList(newRes);
        return newRes
    }
    const processUserAllDashboardList = (dashboardList, userTemplateList) => {
        if ((userTemplateList.length > 0 || dashboardList.length > 0) && !editMode) {
            const combinedArray = [...dashboardList, ...userTemplateList];

            if (combinedArray.length === 0) {
                resetState();
                return;
            }
            
            const sortedItems = combinedArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const favoriteDashboard = sortedItems.find(dashboard => dashboard.isFavorite);
            // console.log(favoriteDashboard)
            if (favoriteDashboard) handleSelectTemplate(favoriteDashboard, sortedItems.indexOf(favoriteDashboard));
            // else handleSelectTemplate(sortedItems[0], 0);
            
            setUserAllDashboardList(sortedItems);
        }
    }
    const handleGroupDashboardTemplatesList = (allDashboard) => {
        setGroupDashboardTemplatesList(allDashboard);
    }
    const handleNoDashboard = (val) => {
        setNoDashboardFound(val)
    }
    const handlePintoDashboardUpdate = () => {
        setSelectedTemplate(selectedDashboardTemplate.dashboardTemplate !== undefined ? selectedDashboardTemplate.dashboardTemplate : selectedDashboardTemplate.details)
        setPinToDashboardUpdates(prev => prev + 1)
    }
    useEffect(()=> {
        // console.log(selectedDashboardTemplate)
        setUpdatedLayout(null)
        if(!editMode)
            setShowDashboardList(false)
    }, [editMode, selectedDashboardTemplate, pinToDashboardUpdates])

    const capitalize = (str) => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };
    const deleteDashboard = async (data, index) => {
        try {
            setAlert(null)
            if(data.personalDashboards || data.personalDashboardsBI)
            {
                if(data.isBI)
                    await deletePersonalDashboardBI({ clientId: data.clientId, userId: data.userId, id: data.id })
                else
                    await deletePersonalDashboard({ clientId: data.clientId, userId: data.userId, id: data.id })

            } else if(data.userDashboard || data.userDashboardBI) {

                if(data.isBI)
                    await deleteUserDashboardBI({ clientId: data.clientId, groupId: data.groupId, uid: data.uid })
                else
                    await deleteUserDashboard({ clientId: data.clientId, groupId: data.groupId, uid: data.uid })

                // const alertMessage = `You have successfully deleted the ${data.name}`

                // handleSuccessUpdate(alertMessage)
                const createdBy = userDetails.User.FirstName + ' ' + userDetails.User.LastName
                const group = groupList.filter(item => item.id === data.groupId)

                const notifications ={
                    uid: await generateUUID(),
                    id: data.groupId,
                    clientId: selectedClientID ? selectedClientID : sessionStorage.getItem('selectedClientID'),
                    message: `${createdBy} removed the ${capitalize(data.name)} dashboard from ${group[0].GroupName} group user's personal dashboard`,
                    createdAt: new Date(),
                    sender: userDetails.User.Email,
                    isDashboard: true,
                    url: '/client/customDashboard'
                }

                const res = groupList.filter((item) => item.id === data.groupId)
                const selectedGroup = res[0]
                let msg = notifications.message

                // await socket.emit("newGroupNotification", notifications);
                let users = []
                if (selectedGroup && selectedGroup.UserList && Array.isArray(selectedGroup.UserList.users)) {
                    users = selectedGroup.UserList.users.filter((item) => item !== userDetails.User.Email);
                } else {
                    console.warn("selectedGroup or selectedGroup.UserList.users is not defined or not an array");
                    users = [] // Set an empty array or handle it as per your requirements
                }
                sendNotificationHandler(users, false, msg, selectedGroup.GroupName)
            }

            const alertMessage = `You have successfully deleted the ${data.name}`
            handleSuccessUpdate(alertMessage, data, index)
            // handleNewUpdate()
        } catch (error) {

        }
    }
    const showDeleteTemplateConfirmation = (data, index, isFromGroup = false) => 
    {
        setAlert(
            <ReactBSAlert
                warning
                title="Warning"
                // style={{ display: "block", marginTop: "-100px" }}
                onConfirm={() => {
                    // console.log({data, isFromGroup})

                    if(!isFromGroup)
                    {
                        if(data.userDashboard || data.personalDashboards || data.personalDashboardsBI || data.userDashboardBI) 
                            deleteDashboard(data, index)
                        else deleteTemplate(data, index)
                    }else{
                        deleteGroupDashboard(data, index)
                    }
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                showCancel={true}
                btnSize=""
            >
                <p>
                    Are you sure you want to delete {data.name}?
                </p>
            </ReactBSAlert>
        );
    };

    const deleteGroupDashboard = async (data, index) => {
        try {
            const param = { clientId: data.clientId, groupId: data.groupId, id: data.id }
            // const newGroupDashboardList = groupDashboardList.filter(item => item.id !==  param.id)

            if(data.isBI)
                await deleteGroupDashboardBI(param)
            else
                await deleteGroupDashboard(param)

            const alertMessage = `You have successfully deleted the ${data.name}`
            handleSuccessUpdate(alertMessage, data, index)
            // handleSuccessDashboardUpdate(alertMessage)
            // // 
            // setGroupDashboardList(newGroupDashboardList)

            // if(index === activeDashboard)
            // {
            //     setSelectedDashboard(null)
            //     setSelectedReportData(null)
            //     setShowReport(false)
            // }

            // const createdBy = userDetails.User.FirstName + ' ' +userDetails.User.LastName
            // const notifications ={
            //     uid: await generateUUID(),
            //     id: data.groupId,
            //     clientId: selectedClientID,
            //     message: `${createdBy} removed the ${data.name} dashboard from ${data.groupName} group's dashboard`,
            //     createdAt: new Date(),
            //     sender: userDetails.User.Email,
            //     isDashboard: true,
            //     url: '/client/customDashboard'
            // }

            // const res = groupList.filter((item) => item.id === data.groupId)
            // const selectedGroup = res[0]
            // let msg = notifications.message

            // await socket.emit("newGroupNotification", notifications);
            // sendNotificationHandler(selectedGroup.Users, false, msg, selectedGroup.GroupName)
        } catch (error) {

        }
    }

    const processDeleteDashboard = (selectedDashboardTemplate, userAllDashboardList, index, data) => 
    {
        const selectedDashboard = {...selectedDashboardTemplate}

        userAllDashboardList.splice(index,1)
        if(selectedDashboard.id === data.id) { 
            if(userAllDashboardList.length > 0) handleSelectTemplate(userAllDashboardList[0],0)
            else if(groupDashboardTemplatesList.length > 0) handleSelectTemplate(groupDashboardTemplatesList[0],0)
            else if((!userAllDashboardList || userAllDashboardList.length === 0) && groupDashboardTemplatesList.length === 0) setNoDashboardFound(true)
            else resetState()
        }
    }
    const deleteTemplate = async (data, index) => {
        try 
        {
            const param = { id: data.id }
            await deleteUserDashboardTemplate(param)
            const alertMessage = `You have successfully deleted the ${data.name}`
            handleSuccessUpdate(alertMessage, data, index)
        } catch (error) {

        }
    }
    const sendNotificationHandler = async (users, isRemoved, msg, groupName) => {
        const createdBy = userDetails.User.FirstName + ' ' + userDetails.User.LastName
        const ClientID = selectedClientID
        const notification = {
          title: `new notification from the "${groupName}" group`,
          body: msg,
          sound: 'default',
          badge: '1',
        };
        
        const clientToken = await mongoFetchToken({ clientid : selectedClientID, groupId: selectedClientID })
        if(!clientToken) return 
        
        const filteredTokens = (clientToken.tokens || [])
        .filter(token => {
            const userEmail = userDetails?.User?.Email?.toLowerCase();
            const usersList = users || []; // Ensure users is an array
            return usersList.includes(token.email.toLowerCase()) && token.email.toLowerCase() !== userEmail;
        })
        .map(token => token.token);
    
        if (filteredTokens.length === 0) return
    
        const message = {
          tokens: filteredTokens,
          data: { ClientID: ClientID, Name: selectedClient.Name ,  type: 'dashboard', Id: selectedClient.id},
          notification: notification,
        };

        SendMultiDeviceNotification(message)
        SendExpoNotifications(message)
    };
    const handleActiveTemplate = (index) => {
        if (activeTemplate === index)
            return { border: `3px solid ${bgColor}` }
        else
            return { border: `3px solid #fff` }
    }
    const handleSuccessUpdate = (msg, data, index) => {
        setAlert(
            <ReactBSAlert
                success
                title="Success"
                onConfirm={async () => {
                    setNewUpdateCount(prevCount => prevCount + 1);
                    setAlert(null)
                    processDeleteDashboard(selectedDashboardTemplate, userAllDashboardList, index, data)
                }}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                <>
                    <p>
                        {msg}
                    </p>
                </>
            </ReactBSAlert>
        );
    }
    const handleNewUpdate = () => {
        setNewUpdateCount(prevCount => prevCount + 1);
    }
    const handleShowBIReport = (data, showReport) => {
        setSelectedReportData(data)
        setShowReport(showReport)
    }
    const toggleTemplateEditModal = (data, index) => {
        // setShowTemplateModal(true)
        sessionStorage.setItem("selectedTemplatetoEdit", data);
        setSelectedTemplatetoEdit(data)
        setIsTemplateEdit(true)
        return history.push(`/client/templates`);
    }
    const handleSelectTemplate = (selectedTemplate, index) => 
    {
        // console.log(selectedTemplate)
        setSelectedDashboardTemplate(null)
        onHandleSelectTemplate(null)
        setActiveDashboard(null)
        setSelectedTemplate([])
        setDashboardItems([])
        handleShowBIReport(null, false)
        setSelectedDashboard(null)
        handleSetDnDLayout(null)
        setActiveTemplate(index)

        if(selectedTemplate === undefined || !selectedTemplate) return

        if(selectedTemplate.isBI)
        {
            handleShowBIReport(selectedTemplate.details, true)
        }

        setTimeout(() => {
            setSelectedDashboardTemplate(selectedTemplate)
            onHandleSelectTemplate(selectedTemplate.name)
            setSelectedTemplate(selectedTemplate.dashboardTemplate !== undefined ? selectedTemplate.dashboardTemplate : selectedTemplate.details)

            if(!selectedTemplate.isPersonalDashboard)
            {
                const selectedGroups = selectedTemplate?.selectedGroupId?.groupsId;
                const usersMap = new Map();
                
                // console.log(clientGroups)
                // console.log(clientUsers)
                clientGroups && clientGroups.forEach(group => {
                    if (selectedGroups && (selectedGroups.includes(group.id) || selectedGroups.includes(group.ClientId))) {
                        group.UserList.users.forEach(user => {
                            const u = clientUsers && clientUsers.find(e => e.Email === user);
                            if (u && !usersMap.has(user)) {
                                usersMap.set(user, { FirstName: u.FirstName, LastName: u.LastName, Email: user, userAccess: u.UserAccess, Avatar: u.Avatar });
                            }
                        });
                    }
                });
                
                const uniqueUsers = Array.from(usersMap.values());
                setDashboardUserWithAccess(uniqueUsers)
                // console.log(uniqueUsers)
            }
           
        }, 300);
    }
    const [updatedLayout, setUpdatedLayout] = useState(null)
    const handleDeleteDashboardTemplateItem = (item, layouts) => {
        const itemIndex = selectedTemplate.findIndex(e => e.uid === item.uid)
        const newVal = selectedTemplate.filter(e => e.uid !== item.uid)
        const updatedLayout = layouts.filter(item => item.i !== String(itemIndex));

        newVal && newVal.map((item, index) => {
            updatedLayout[index].i = String(index)
        })
        setUpdatedLayout(updatedLayout)
        setSelectedTemplate(newVal)
       
        if(selectedDashboardTemplate.personalDashboards)
        {
            selectedDashboardTemplate.details = newVal
            updatePersonalDashboard(selectedDashboardTemplate)
        }else{
            selectedDashboardTemplate.dashboardTemplate = newVal
            registerDashboardTemplate(selectedDashboardTemplate)
        }
        
        // // handleNewUpdate()
        // const alertMessage = `You have successfully deleted the ${item.name}`
        // handleSuccessDashboardUpdate(alertMessage)
        // selectedDashboardTemplate
    }
    const handleUpdateLayout = () => {
        setUpdatedLayout(null)
    }
    const handleSetItemIndexToEdit = (index) => {
        setItemIndexToEdit(index)
    }

    useEffect(() => {
        // console.log(selectedTemplate)
    },[selectedTemplate])

    const handleAddtoFavorite = async (param) => 
    {
        toggleShowDashboardOption();
        setLoading(true);

        if(param.from === 'personal') {
            const dashboardIndex = param.index;
            const dashboard = param.userTemplateList[dashboardIndex];
            const newParam = { ...dashboard, user: userDetails.User.Email, userId: userDetails.User.UserID };

            if (dashboard?.isFavorite) {
                clearFavorite();
                userAllDashboardList[dashboardIndex].isFavorite = false;
                await updateTemplateIsFavorite(newParam);
            } else {
                if (!dashboard.isBI) clearFavorite();
                userAllDashboardList[dashboardIndex].isFavorite = true;
                await updateTemplateIsFavorite(newParam);
            }

            setDashboardUpdate(prev => prev + 1);
        } else {
            const groupIndex = param.groupIndex;
            const dashboardIndex = param.index;
            const groupDashboard = param.groupAllDashboardList[groupIndex].dashboardList[dashboardIndex];

            if(groupDashboard.isFavorite) groupDashboardTemplatesList[dashboardIndex].isFavorite = false;
            else groupDashboardTemplatesList[dashboardIndex].isFavorite = true;

            groupDashboard.groupId = param.groupId

            await updateGroupDashboardIsFavorite(groupDashboard)
            setDashboardUpdate(prev => prev + 1);
        }
    }
    
    const clearFavorite = () => {
        dashboardList.forEach((item) => {
            item.isFavorite = false
        })
        userTemplateList.forEach((item) => {
            item.isFavorite = false
        })
    }
    const toggleShowDashboardOption = () => {
        onToggleShowDashboardOption()
        // setShowDashboardOption(!showDashboardOption)
    }
    const handleShowDashboardList = () => {
        setShowDashboardList(!showDashboardList)
    }
    const handleSetDnDLayout = (value) => {
        setDnDLayout(value)
    }
    const handleSetClientUsers = (value) => {
        setClientUsers(value)
    }
    const handleSetClientGroups = (value) => {
        setClientGroup(value)
    }
    const handleEVAChatWidth = (width) => {
        setEVAChatWidth(width)
    }
    const handleSetDashboardUpdate = () => {
        setDashboardUpdate(prev => prev + 1)
    }
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const cellSpacing = [10, 10];
    return (
        loading ? <CustomSpinner />
        :
        <div style={{ overflow: 'hidden' }}>
            {alert}

            {dashboardUserWithAccess && dashboardUserWithAccess.length > 0 &&
                <DashboardUserWithAccess 
                    bgColor={bgColor}
                    textColor={textColor}
                    dashboardUserWithAccess={dashboardUserWithAccess}
                />
            }
            
            {/* {showDashboardOption &&  */}
                <div style={{ display: !showDashboardOption && 'none'}}>
                    <DashboardListComponent
                        selectedDashboardTemplate={selectedDashboardTemplate}
                        userTemplateList={userAllDashboardList}
                        dashboardUpdate={dashboardUpdate}
                        handleSelectTemplate={handleSelectTemplate}
                        onToggleShowDashboardOption ={onToggleShowDashboardOption}
                        toggleShowDashboardOption={toggleShowDashboardOption}
                        handleAddtoFavorite={handleAddtoFavorite}
                        handleNoDashboard={handleNoDashboard}
                        handleSetClientUsers={handleSetClientUsers}
                        handleSetClientGroups={handleSetClientGroups}
                        handleGroupDashboardTemplatesList={handleGroupDashboardTemplatesList} />
                </div>
            {/* } */}
            {editMode && showDashboardList &&
                <>
                    <AllDashboard
                        editMode={editMode}
                        loading={loading}
                        userAllDashboardList={userAllDashboardList}
                        groupDashboardTemplatesList={groupDashboardTemplatesList}
                        handleActiveDasboard={handleActiveTemplate}
                        showDeleteTemplateConfirmation={showDeleteTemplateConfirmation}
                        handleSelectTemplate={handleSelectTemplate}
                        toggleTemplateEditModal={toggleTemplateEditModal}
                        handleAddtoFavorite={handleAddtoFavorite}
                        dashboardUpdate={dashboardUpdate}
                        handleGroupDashboardTemplatesList={handleGroupDashboardTemplatesList}
                        selectedDashboardTemplate={selectedDashboardTemplate}
                        resetState={resetState}
                        handleSetDashboardUpdate={handleSetDashboardUpdate}
                    />
                </>
            }
            
            {showReport && selectedReportData && 
                <>
                    {editMode && 
                        <div 
                            onClick={handleShowDashboardList} 
                            className="rptDashboardBtn"
                            style={{ backgroundColor: bgColor, color: textColor }}
                        >
                            {!showDashboardList ? "Show" : "Hide"} Dashboard List
                        </div>
                    }
                
                    <ReportView
                        data={selectedReportData} />

                    {showEVA && 
                        <EVADashboard
                            token={evaIframeUrl} 
                            showChat={showChat} 
                            noEvaAccess={noEvaAccess} 
                            selectedClientID={selectedClientID}
                            handleNewUpdate={handleNewUpdate} 
                            selectedDashboardTemplate={selectedDashboardTemplate}
                            handlePintoDashboardUpdate={handlePintoDashboardUpdate}
                            handleEVAChatWidth={handleEVAChatWidth}
                        />
                    }
                </>
                
            } 
            
            {noDashboardFound && 
                <NoDashboardFound />
            }
            {(selectedDashboardTemplate || editMode) && !showReport && 
                <div style={{ display: 'flex' }}>
                    {/* <Col sm='12' lg={!showEVA ? '12' : '6'} md={!showEVA ? '12' : '6'} > */}
                    <div className="dndContainer" style={showEVA ? { width: screenWidth - evaChatWidth, height: screenHeight - 116, overflow: 'auto' } : {width: screenWidth}}>
                        {(selectedTemplate && selectedTemplate.length > 0) || editMode ?
                            <>
                                <DashboardItemDnD 
                                    editMode={editMode}
                                    handleIsDimensionChange={handleIsDimensionChange}
                                    onResizeItem={onResizeItem}
                                    showEVA={showEVA}
                                    isEditIndex={itemIndexToEdit}
                                    handleSetItemIndexToEdit={handleSetItemIndexToEdit}
                                    selectedDashboardTemplate={selectedDashboardTemplate}
                                    selectedTemplate={selectedTemplate}
                                    handleNewUpdate={handleNewUpdate}
                                    handleDeleteDashboardTemplateItem={handleDeleteDashboardTemplateItem}
                                    handleShowDashboardList={handleShowDashboardList}
                                    showDashboardList={showDashboardList}
                                    handleSetDnDLayout={handleSetDnDLayout}
                                    handleUpdateLayout={handleUpdateLayout}
                                    DnDLayout={DnDLayout}
                                    pinToDashboardUpdates={pinToDashboardUpdates}
                                    updatedLayout={updatedLayout}
                                />
                            </>
                        :
                        <div style={{ padding: '20px 0px', textAlign: 'center' }}>
                            No dashboard item was found.
                        </div>
                        }
                    </div>

                    {showEVA && 
                        <EVADashboard
                            token={evaIframeUrl} 
                            showChat={showChat} 
                            noEvaAccess={noEvaAccess} 
                            selectedClientID={selectedClientID}
                            handleNewUpdate={handleNewUpdate} 
                            selectedDashboardTemplate={selectedDashboardTemplate}
                            handlePintoDashboardUpdate={handlePintoDashboardUpdate}
                            handleEVAChatWidth={handleEVAChatWidth}
                        />
                    }
                </div>
            }
        </div>

    );
}

export default PersonalDashboard;
