import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import Messages from "./Messages";
import { fetchEVAMessages, getMessageReact, sendEVAPrivateMessage } from "../services/mongoDB";
import EVAResponse from "./response/EVAResponse";
import { EVAPrivate, EVAService, EVAServiceStream } from "../services/EVAService";
import { CloseTab, IconBar, IconCard, IconGrid, IconLine, IconPie } from "./ui/icon";
import EVATyping from "./ui/eva-typing";
import EVAWelcome from "./response/EVAWelcome";
import { evaThemeLighter } from "services/EVAResponseService";
import EVAStreamLoading from "./response/EVAStreamLoading";
import Loading from "chat-components/ui/loading";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { MainContext } from "context/mainContext";
import EVAAnswer from "./response/EVAAnswer";
import EVADashboardAnswer from "./response/EVADashboardAnswer";
import DashboardLoading from "chat-components/ui/dashboardLoading";
import Select from 'react-select';

const EVAChatDashboard = ({ categories, sub_category, KPIList, onSelectUUID, selectedUUID, evaDateFilters, 
    toggleEVAModal, evaDashboardMessage, handleEVADashboardMessage, handleSelectTemplateItems }) => {

    const [business, setBusiness] = useState(null)
    const [client, setClient] = useState(null)
    const [user, setUser] = useState(null)
    
    const [messages, setMessages] = useState([]);
    const [streamMessages, setStreamMessages] = useState([]);
    const [isStreamLoading, setIsStreamLoading] = useState(true)
    const [isEVATyping, setIsEVATyping] = useState(false)
    const [inputText, setInputText] = useState('');
    const [streamMessage, setStreamMessage] = useState([])
    const messagesContainerRef = useRef(null);
    const [isWelcomeMessage, setIsWelcomeMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [text, setText] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [selectedDashboardSize, setSelectedDashboardSize] = useState("");
    const [categoryList, setCategoryList] = useState([])
    const [selectedKPI, setSelectedKPI] = useState([])
    const [selectedKPI_id, setSelectedKPI_id] = useState([])
    const [filterIndex, setFilterIndex] = useState(null);
    const [isShowQuickFilter, setIsShowQuickFilter] = useState(false)

    const dashSize = [
        { name: "Compact (4-6 visuals)", count: 6 },
        { name: "Standard (7-9 visuals) - Recommended", count: 9 },
        { name: "Comprehensive (10+ visuals)", count: 10 }
    ]
    const {
        bgColor,
        textColor,
        selectedClientID,
        userDetails,
        firmDetails,
        isTemplateEdit,
        selectedTemplatetoEdit, 
        selectedClient,
        selectClientIntegration
    } = useContext(MainContext);

    const theme = { PrimaryColor: bgColor, TextColor: textColor }

    useEffect(() => {
        // console.log(evaDashboardMessage)
        // if(evaDashboardMessage === undefined) setIsLoading(true)
        if(Object.keys(evaDashboardMessage).length === 0 || evaDashboardMessage.length === 0) setIsLoading(true)
        if(Object.keys(evaDashboardMessage).length === 1 || evaDashboardMessage.length === 1) setIsLoading(false)
        // else setIsLoading(false)
        setTimeout(() => {
            scrollToBottom()
        }, 300);
    },[evaDashboardMessage])

    useEffect(()=> {
        setBusiness({ uid: firmDetails.AccountingFirmID })
        setClient({ uid: selectedClientID })
        setUser({ id: userDetails.id, fullName: `${userDetails.User.FirstName} ${userDetails.User.LastName}`, uid: userDetails.User.UserID })
        
        const newRes = categories.map(item => { return { value: item, label: item } })
        // console.log(evaDateFilters)
        setCategoryList(newRes);
    }, [selectedUUID])
    
    useEffect(() => {
        const style = document.createElement("style");
        style.innerHTML = `.home { height: 100% !important; position: unset; }`;
        document.head.appendChild(style);
    }, []);

    useEffect(() => {
        // let filteredKPI = KPIList.filter(item => item.category === selectedCategory);
    
        // if (selectedSubCategory.length > 0) {
        //     filteredKPI = filteredKPI.filter(item => selectedSubCategory.includes(item.sub_category));
        // }
    
        // // Function to shuffle an array
        // const shuffleArray = (array) => {
        //     return array
        //         .map(item => ({ item, sort: Math.random() })) // Assign a random number to each item
        //         .sort((a, b) => a.sort - b.sort) // Sort by random number
        //         .map(({ item }) => item); // Extract the shuffled items
        // };
    
        // // Shuffle before selecting
        // const shuffledKPI = shuffleArray(filteredKPI);
        
        // // Select based on the dashboard size (if it's '10', select all)
        // const limitedKPI = selectedDashboardSize === '10' ? shuffledKPI : shuffledKPI.slice(0, selectedDashboardSize);
        // const filteredKPI_ids = limitedKPI.map(item => item.my_row_id);
    
        // setSelectedKPI_id(filteredKPI_ids);
        // setSelectedKPI(limitedKPI);
    
    }, [selectedCategory, selectedSubCategory, selectedDashboardSize]);

    const handleSendEVAStreamMessage = async (text, fromFile = false, selectedCategory, selectedSubCategory, selectedDashboardSize, quickFilter = false) => {
        setIsLoading(true)
        const newMessage = {
            uuid: selectedUUID,
            clientid: selectedClientID,
            userid: userDetails.User.UserID,
            text: `dashboard mode, ${text}`,
            sender: userDetails.User.UserID,
            user: mapUser(),
            createdAt: new Date(),
        }

        handleEVADashboardMessage(newMessage)

        const raw = {
            "chatId": selectedUUID,
            "query": `dashboard mode, ${text}`,
            "UserInfo": {
                "chatId": selectedUUID,
                "UserId": userDetails.User.UserID,
                "Name": user.fullName,
                "ClientId": selectedClientID,
                "BusinessId": business.uid
            }
        };

        try {
            const response = await fetch('https://evabetaprod.azurewebsites.net/eva_stream', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(raw)
            });
            
            const reader = response.body.getReader();
            let chunks = '';
            const streamMessageArray = []

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                chunks += new TextDecoder().decode(value);
                const messagesArray = chunks.split('\n');
                streamMessageArray.push(chunks)
                setStreamMessage([...streamMessageArray])
            }

            setIsStreamLoading(false)
            setStreamMessage([])

            const newMessage = {
                uuid: selectedUUID,
                clientid: selectedClientID,
                userid: userDetails.User.UserID,
                _id: Math.floor(Math.random() * 10000000000000),
                text: streamMessageArray[streamMessageArray.length - 1],
                sender: userDetails.User.UserID,
                user: {
                    _id: Math.floor(Math.random() * 10000000000000),
                    sender: Math.floor(Math.random() * 10000000000000),
                    name: "EVA",
                },
                quickFilter,
                query: text,
                createdAt: new Date(),
                isDefaultDashboard: false,
                selectedDashboardSize,
                selectedCategory,
                selectedSubCategory
            }

            // console.log(newMessage)
        
            handleEVADashboardMessage(newMessage)
            setIsLoading(false)

     
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
        setIsStreamLoading(false)
        setStreamMessage([])
    }

    function mapUser() {
        return {
            _id: userDetails.User.UserID,
            sender: userDetails.User.UserID,
            name: user.fullName,
        };
    }

    const scrollToBottom = () => {
        messagesContainerRef.current?.scrollTo(0, messagesContainerRef.current?.scrollHeight);
    };

    function parseDashboardTemplateId(value) {
        if (typeof value === "string") {
            if (value.startsWith("[") && value.endsWith("]")) {
                // It's a stringified array, parse it
                return JSON.parse(value);
            } else {
                // It's a comma-separated string, split and convert to numbers
                return value.split(",").map(num => parseInt(num.trim(), 10));
            }
        }
        return []; 
    }

    const getIcon = (visual_type) => {
        if(visual_type === 'Grid')
            return <IconGrid />

        if(visual_type === 'Pie Chart')
            return <IconPie />

        if(visual_type === 'Bar Chart')
            return <IconBar />

        if(visual_type === 'Line Chart')
            return <IconLine />

        if(visual_type === 'Card')
            return <IconCard />

        return <IconGrid />
    }

    const handleSelectKPIs = (selectedKPI) => {
        handleSelectTemplateItems(selectedKPI)
        toggleEVAModal()
    }

    const handleGenerateQuickFilterDashboard = () => {
        const prompt = `dashboard size: ${selectedDashboardSize}, category: ${selectedCategory}, sub-category: ${selectedSubCategory.map((item) => { return `${item}` })}, query: ${text}`
        const quickFilter = true
        handleSendEVAStreamMessage(text, false, selectedCategory, selectedSubCategory, selectedDashboardSize, quickFilter)
        setText("")
    }
    
    const handleShowQuickFilters = () => {
        setIsShowQuickFilter(!isShowQuickFilter)
    }
    const EVADashboardResponse = ({ index, message }) => {
        const text = message.text
        
        if(!text) return (null)

        const jsonString = text.substring(text.indexOf("["));
        const jsonArray = JSON.parse(jsonString);
        const result = jsonArray[1]; // Extract the second element

        // console.log(message)

        const selectedKPIId = parseDashboardTemplateId(result.dashboardTemplateId)

        const selectedKPI = selectedKPIId && selectedKPIId.length > 0 && selectedKPIId.map((item) => {
            const findItem = KPIList.find(kpi => kpi.my_row_id === item)
            return findItem
        })


        // if(selectedKPI && selectedKPI.length > 0)
        return (
            <>
               
                {result.answer &&  !message?.quickFilter &&  message?.isDefaultDashboard && 
                    <div className="mb-3">
                        <EVADashboardAnswer 
                            answer={result.answer} 
                            query={""} 
                            isWelcomeMessage={true} 
                            handleSendEVAStreamMessage={handleSendEVAStreamMessage}
                            isDefaultDashboard={message?.isDefaultDashboard}
                            quickFilter={message.quickFilter}
                        />
                    </div>
                }
                
                {!message?.isDefaultDashboard ?
                    <>
                        {/* <div>
                            <div>Suggested <strong>{handleReplaceDashboardKeywords(evaDashboardMessage[index - 1]?.text)}</strong> dashboard:</div>
                            {selectedKPI.map((item) => (
                                <div key={item.id || item.display_name} className="mb-2 ml-2" style={{ fontWeight: 'normal', fontSize: 14 }}>
                                    {getIcon(item.visual_type)} {item.display_name}
                                </div>
                            ))}
                        </div> */}
                      
                        {message.quickFilter ? (
                            <>
                                <div className="category-container mb-3">
                                    {message.selectedDashboardSize && (
                                        <>
                                            <strong>Dashboard Size</strong>: {message.selectedDashboardSize}
                                        </>
                                    )}
                                    {message.selectedCategory && (
                                        <>
                                            {" | "}
                                            <strong>Category</strong>: {message.selectedCategory}
                                        </>
                                    )}
                                    {message.selectedSubCategory && message.selectedSubCategory.length > 0 && (
                                        <>
                                            {" | "}
                                            <strong>Sub-Category</strong>:
                                            {message.selectedSubCategory.map((item, index) => (
                                                <span key={index}> {item}</span>
                                            ))}
                                        </>
                                    )}
                                    {message.query && (
                                        <>
                                            {" | "}
                                            <strong>Query</strong>: {message.query}
                                        </>
                                    )}
                                </div>
                                
                                <div className="mb-3" style={{ fontWeight: 'normal' }}>These are the visuals I recommend based on your feedback and quick filters. Would you like me to load this dashboard? Otherwise keep describing more what you are after and I can have another go</div>
                                
                                {selectedKPI && selectedKPI.map((item) => (
                                    <div key={item.id || item.display_name} className="mb-2 ml-2" style={{ fontWeight: '600', fontSize: 13 }}>
                                        {getIcon(item.visual_type)} {`  ${item.display_name}`}
                                    </div>
                                ))}
                            </>
                        ) : (
                            message.query && (
                                <>
                                    <div className="category-container mb-3">
                                        <strong>Default Dashboard</strong> : {message.query}
                                    </div>
                                    <div className="mb-3" style={{ fontWeight: 'normal' }}>
                                        These are the visuals that I've found from our Recommended dashboard—take a look and let me know if any catch your eye!
                                        Otherwise, try using the quick filter to find the visuals that best suit your needs.
                                    </div>
                                    
                                    {selectedKPI && selectedKPI.map((item) => (
                                        <div key={item.id || item.display_name} className="mb-2 ml-2" style={{ fontWeight: '600', fontSize: 13 }}>
                                            {getIcon(item.visual_type)} {`  ${item.display_name}`}
                                        </div>
                                    ))}
                                </>
                                
                                
                            )
                        )}
                
                        <Button className="mt-3" color="success" size="sm" type="button" outline onClick={() => handleSelectKPIs(selectedKPI)}>
                            {/* Generate Dashboard */}
                            Load result to dashboard
                            {/* Generate {message.selectedCategory && message.selectedCategory} Dashboard   */}
                            {/* {handleReplaceDashboardKeywords(evaDashboardMessage[index - 1]?.text)} Dashboard */}
                        </Button>
                    </>
                    :
                    <>
                        {/* <Button className="mt-3" color="primary" size="sm" type="button" outline onClick={() => handleShowQuickFilters()}>
                            {isShowQuickFilter ?  'Hide' : 'Show'} Quick Filters
                        </Button> */}
                    </>
                }
            </>
        )
        // else
        // return ( <>{text}</>)
    }
    const handleReplaceDashboardKeywords = (message) => {
        if(!message) return ""
        const newMessage = message.replace("dashboard mode, ", "")
        return newMessage
    }

    const inputRef = useRef(null);

    const handleSend = async () => {
        if(text)
        {
            handleSendEVAStreamMessage(text, false)
            setText("")
        }
    };
    const handleClick = async () => {
        onSelectUUID();
    };
    const handleCategoryChange = (value) => {
        // console.log(value)
        // const selectedCategory = value && value.length > 0 && value.map((item) => item.value)

        // const sub_categories = sub_category.filter(item => selectedCategory.includes(item.category_sub_category))
        // const sorted_sub_categories = sub_categories.sort((a, b) => {
        //     // First, compare category_sub_category
        //     if (a.category_sub_category < b.category_sub_category) return -1;
        //     if (a.category_sub_category > b.category_sub_category) return 1;
            
        //     // If category_sub_category is the same, compare sub_category
        //     return a.sub_category.localeCompare(b.sub_category, undefined, { numeric: true });
        // });

        // setSubCategoryList(sorted_sub_categories)
        // console.log(categoryList)
        setSelectedCategory(value.target.value);
        const sub_categories = []
        sub_category && sub_category.map((item) => {
            if(item.category_sub_category === value.target.value)
                sub_categories.push(item.sub_category)
        })
        sub_categories.sort((a, b) => a.localeCompare(b));
        const newRes = sub_categories.map(item => { return { value: item, label: item } })
        setSubCategoryList(newRes)
        setSelectedSubCategory([])
    };
    const handleSubCategoryChange = (value) => {

        const newVal = value.map((item) => {
            return item.value
        })

        setSelectedSubCategory(newVal);
    };

    const handleSelectDashboardSize = (e) => {
        setSelectedDashboardSize(e.target.value);
    };
    
    return (
        <Modal isOpen={true} className="modal-dialog" size="xl">
            <>
            {/* <ModalHeader>
                <h2>EVA dashboard</h2>
            </ModalHeader> */}
            <ModalBody style={{ padding: 10 }}>
            <div className="evaDashboardContainer">
                {isLoading && <DashboardLoading />}
                {1 === 2 && 
                    <div className="EVaDashboardWizard" style={{ border: `2px solid ${textColor}`, height: screenHeight - 700, width: '30%' }}>
                        <Form>
                            <h2>Quick Filters</h2>
                            <FormGroup>
                                <Label 
                                    for="categorySelect"
                                    style={{ fontWeight: '600', fontSize: 15, marginBottom: 5}}
                                >
                                    Select Dashboard Size
                                </Label>
                                <Input
                                    type="select"
                                    id="categorySelect"
                                    value={selectedDashboardSize}
                                    onChange={handleSelectDashboardSize}
                                >
                                    <option value="">Dashboard Size</option>
                                    {dashSize.map((size, index) => (
                                        <option key={index} value={size.name}>{size.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="categorySelect" style={{ fontWeight: '600', fontSize: 15, marginBottom: 5}}>Category</Label>
                                <Input
                                    type="select"
                                    id="categorySelect"
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                >
                                    <option value="">Select Category</option>
                                    {categories.map((cat, index) => (
                                        <option key={index} value={cat}>{cat}</option>
                                    ))}
                                </Input>
                                {/* <Label 
                                    htmlFor="categories" 
                                    style={{ 
                                        fontWeight: 'bold', 
                                        fontSize: 14, 
                                        marginBottom: 7
                                }}>Group List</Label>
                                <Select
                                    id="categories"
                                    defaultValue={selectedCategory}
                                    isMulti
                                    name="colors"
                                    options={categoryList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={handleCategoryChange}
                                /> */}
                            </FormGroup>
                            <FormGroup>
                                {/* <Label for="categorySelect">Sub Category</Label>
                                <Input
                                    type="select"
                                    id="categorySelect"
                                    value={selectedSubCategory}
                                    onChange={handleSubCategoryChange}
                                >
                                    <option value="">Select Sub-Category</option>
                                    {subCategoryList.map((cat, index) => (
                                        // <option key={index} value={cat.sub_category}>{cat.sub_category}</option>
                                        <option key={index} value={cat}>{cat}</option>
                                    ))}
                                </Input> */}
                                <Label for="sub-categories" style={{ fontWeight: '600', fontSize: 15, marginBottom: 5}}>Sub Category</Label>
                                <Select
                                    id="sub-categories"
                                    defaultValue={selectedSubCategory}
                                    isMulti
                                    name="colors"
                                    options={subCategoryList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={handleSubCategoryChange}
                                />
                            </FormGroup>
                            {/* <FormGroup>
                                <Label 
                                    for="categorySelect"
                                    style={{ fontWeight: '600', fontSize: 15, marginBottom: 5}}
                                >
                                    Date filters
                                </Label>
                                <Input
                                    type="select"
                                    id="categorySelect"
                                    value={filterIndex}
                                    onChange={(e) => setFilterIndex(e.target.value)}
                                >
                                    <option value="">Select a Filter</option>
                                    {evaDateFilters.map((filter, index) => (
                                        <option key={index} value={index}>
                                            {filter.date_name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup> */}
                            <FormGroup>
                                {selectedKPI && selectedKPI.length > 0 &&
                                    <>
                                        {/* {selectedKPI.map((item, index) => (
                                            <div key={item.id || item.display_name} className="mb-2 ml-2" style={{ fontWeight: 'normal', fontSize: 14 }}>
                                                {getIcon(item.visual_type)} {item.display_name}
                                            </div>
                                        ))} */}
          
                                    </>
                                }
                                <Button className="mt-3" color="primary" size="sm" type="button" outline onClick={() => handleGenerateQuickFilterDashboard()}>
                                    Generate Dashboard
                                </Button>
                            </FormGroup>
                            
                        </Form>
                    </div>
                }
                <div className="chat" style={{ border: `2px solid ${textColor}`, height: screenHeight - 300, width: '100%', position: 'relative' }}>
                   
                    <div className='home'>
                        <div className="evaContainer">
                            <div className="chat" style={{ border: `2px solid #e7e7e7` }}>
                                <div className="messages" ref={messagesContainerRef} style={{ height: isShowQuickFilter ? 'calc(100% - 250px)' : 'calc(100% - 0px)'}}>
                                
                                <>
                                    {evaDashboardMessage.length > 0 &&
                                        evaDashboardMessage.map((message, index) =>
                                            message.user.name === "EVA" ? (
                                                <div key={index} className="message leftMessage">
                                                    {message.isStreamMessage ? (
                                                        <EVAStreamLoading streamMessage={streamMessage} />
                                                    ) : (

                                                        <EVADashboardResponse
                                                            index={index}
                                                            message={message}
                                                        />
                                                        
                                                    )}
                                                </div>
                                            ) : (
                                                null
                                                // <div key={index} className="message owner">
                                                //     <div className="userMessage" style={evaThemeLighter(100, theme)}>
                                                //         <div className="messageText">{handleReplaceDashboardKeywords(message.text)}</div>
                                                //     </div>
                                                // </div>
                                            )
                                    )}
                                    {evaDashboardMessage.length > 1 &&
                                        <>
                                            <div>Not what you are after? Why not share more details and let's set new filters.</div>
                                            <Button className="mt-1" color="primary" size="sm" type="button" outline onClick={() => handleShowQuickFilters()}>
                                                {isShowQuickFilter ?  'Hide' : 'Show'} Quick Filters
                                            </Button>
                                        </>
                                    }
               
                                </>
                                
                                </div>
                                {isShowQuickFilter &&
                                    <div style={{ borderTop: '2px solid #e7e7e7', padding: '5px 10px', height: 32 }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <h3>Quick Filters</h3>
                                            <Button color="danger" size="sm" type="button" outline onClick={() => handleShowQuickFilters()}>
                                                X
                                            </Button>
                                        </div>
                                     
                                        <div className="quick-filter">
                                            <div className="quick-filter-item">
                                                <Label 
                                                    for="categorySelect"
                                                    style={{ fontWeight: '600', fontSize: 13, marginBottom: 5}}
                                                >
                                                    Select Dashboard Size
                                                </Label>
                                                <Input
                                                    type="select"
                                                    id="categorySelect"
                                                    size={"sm"}
                                                    value={selectedDashboardSize}
                                                    onChange={handleSelectDashboardSize}
                                                >
                                                    <option value="">Dashboard Size</option>
                                                    {dashSize.map((size, index) => (
                                                        <option key={index} value={size.name}>{size.name}</option>
                                                    ))}
                                                </Input>
                                            {/* </div>
                                            <div className="quick-filter-item"> */}
                                                <Label for="categorySelect" style={{ fontWeight: '600', fontSize: 13, marginBottom: 5, marginTop: 10 }}>Category</Label>
                                                <Input
                                                    type="select"
                                                    id="categorySelect"
                                                    value={selectedCategory}
                                                    onChange={handleCategoryChange}
                                                    size={"sm"}
                                                >
                                                    <option value="">Select Category</option>
                                                    {categories.map((cat, index) => (
                                                        <option key={index} value={cat}>{cat}</option>
                                                    ))}
                                                </Input>
                                            </div >
                                            <div className="quick-filter-item">
                                                <Label for="sub-categories" style={{ fontWeight: '600', fontSize: 13, marginBottom: 5}}>Sub Category</Label>
                                                <Select
                                                    id="sub-categories"
                                                    defaultValue={selectedSubCategory}
                                                    isMulti
                                                    name="colors"
                                                    options={subCategoryList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={handleSubCategoryChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-3">Let me know the type of dashboard you would like me to build and areas to focus on. type and tell</div>
                                        <div className="input" style={{ padding: 3, height: 45 , margin: 0 }}>
                                            <input
                                                type="text"
                                                ref={inputRef}
                                                placeholder=""
                                                onChange={(e) => setText(e.target.value)}
                                                value={text}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') handleGenerateQuickFilterDashboard();
                                                }}
                                            />
                                            <div className="send" style={{ width: 172 }}>
                                                <button  style={{ backgroundColor: theme.PrimaryColor, padding: '5px 10px' }} onClick={handleGenerateQuickFilterDashboard}>Generate Dashboard</button>
                                            </div>
                                        </div>
                                    </div>    
                                }
                              
                                {/* {isEVATyping && <EVATyping text=". . . ." delay={150} isEVAPage={isEVAPage} infinite />} */}
                                                                    {/* <div className="new">
                                            <button style={evaThemeLighter(100, theme)} onClick={handleClick}>Clear Dashboard</button>
                                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </ModalBody>
            <ModalFooter style={{ padding: 10 }}>
                {/* <Button color="primary" onClick={handleSaveDashboardTemplatesKPI} disabled={!selectedSubCategory}>Save as KPI</Button>{' '} */}
                <Button color="warning" size="sm" outline onClick={toggleEVAModal}>Close EVA</Button>
            </ModalFooter>
            </>
        </Modal>
    );
};

export default EVAChatDashboard;