import CustomSpinner from 'components/Misc/CustomSpinner';
import { MainContext } from 'context/mainContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { groupDashboardsBI, groupDashboardTemplate, groupDashboards, getCustomGroups } from 'services/mongoDB';
import { useHistory } from "react-router";

function DashboardListComponent({ selectedDashboardTemplate, userTemplateList, handleSelectTemplate, onToggleShowDashboardOption, toggleShowDashboardOption, 
                                    handleAddtoFavorite, handleNoDashboard, handleSetClientUsers, handleSetClientGroups,
                                    handleGroupDashboardTemplatesList, dashboardUpdate }) 
{
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID, selectedClient } = useContext(MainContext);
    const [groupDashboardList, setGroupDashboardList] = useState(null)
    const [dashboardTemplatesList, setDashboardTemplateList] = useState([])
    const [groupList, setGroupList] = useState(null)
    const [loading, setLoading] = useState(true);
    const [groupAllDashboardList, setGroupAllDashboardList] = useState([])
    const [isMultiFavorite, setIsMultiFavorite] = useState(false)

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        const loadData = async () => {
            if (!isMounted) return;

            const param = {
                'ClientId': selectedClientID,
                'email': userDetails.User.Email || ''
            };

            try {
                const res = await getCustomGroups(param);
                handleSetClientGroups(res)
                const users = selectedClient?.User?.map(item => item.Email) || [];
                const groups = [{
                    "id": selectedClient.ClientID,
                    "Users": users,
                    "GroupName": selectedClient.Name,
                    "ClientId": selectedClient.ClientID
                }];

                const myGroups = [...groups, ...(res || [])];

                const TemplateParam = {
                    clientId: selectedClientID
                };

                const [groupDashboardsData, groupDashboardsBIData, templateRes] = await Promise.all([
                    groupDashboards(TemplateParam),
                    groupDashboardsBI(TemplateParam),
                    groupDashboardTemplate(TemplateParam)
                ]);

                let formatedGroupDashboardsBIData = []

                if(groupDashboardsBIData)
                    formatedGroupDashboardsBIData = groupDashboardsBIData.map((item) => ({ ...item, isBI: true }))

                const groupDashboardTemplatesList = (templateRes || []).filter(item => !item.isPersonalDashboard && !item.isSoftDelete);

                const allGroupDashboard = [
                    ...(groupDashboardsData || []),
                    ...(formatedGroupDashboardsBIData || []),
                    ...groupDashboardTemplatesList
                ];

                allGroupDashboard.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                const groupDashboardListItems = []
                const groupDashboardList = myGroups.map(group => {
                    const groupId = String(group.id)

                    const dashboards = allGroupDashboard.filter(dashboard =>
                        // (String(dashboard.groupId) === String(groupId) && dashboard.isPersonalDashboard === false) ||
                        (dashboard.selectedGroupId?.groupsId.some(id => String(id) === String(groupId)))
                    );
                        
                    if (dashboards && dashboards.length > 0) {
                        groupDashboardListItems.push(...dashboards);
                    }

                    return {
                        groupId,
                        groupName: group.GroupName,
                        dashboardList: dashboards
                    };
                });

                // console.log(userTemplateList)
                // console.log("all: ", allGroupDashboard);
                // console.log("userTemplateList: ", userTemplateList);
                // console.log("group dashboard: ", groupDashboardList);
                // console.log("group dashboard: ", groupDashboardList[0]?.dashboardList || []);
                // console.log(userTemplateList.length === 0 && groupDashboardList[0]?.dashboardList && groupDashboardList[0]?.dashboardList.length > 0)

                const withPersonalDashboardFavorite = userTemplateList.some(template => template.isFavorite);

                if (!withPersonalDashboardFavorite) {
                    groupDashboardList.some(group => {
                        if (group.dashboardList.length > 0) {
                            const favoriteIndex = group.dashboardList.findIndex(dashboard => dashboard.isFavorite);
                            if (favoriteIndex !== -1) {
                                handleSelectTemplate(group.dashboardList[favoriteIndex], favoriteIndex);
                                return true; // Stop iteration once a favorite is found
                            }
                        }
                        return false;
                    });

                    let favoriteCount = 0

                    groupDashboardList.some(group => {
                        if (group.dashboardList.length > 0) {
                            const favoriteIndex = group.dashboardList.findIndex(dashboard => dashboard.isFavorite);
                            if (favoriteIndex !== -1) favoriteCount++
                        }
                    });

                    if(favoriteCount > 1) setIsMultiFavorite(true)
                    else setIsMultiFavorite(false)
                }

                let withGroupDashboard = false

                if(userTemplateList.length === 0)
                    for (const group of groupDashboardList) {
                        if (group.dashboardList.length > 0) {
                            withGroupDashboard = true
                            handleSelectTemplate(group.dashboardList[0], 0);
                            break; // Stop after the first found dashboard
                        }
                    }

                // console.log()
                // if (userTemplateList.length === 0 && groupDashboardList[0]?.dashboardList && groupDashboardList[0]?.dashboardList.length > 0) {
                //     handleSelectTemplate(groupDashboardList[0]?.dashboardList[0], 0);
                // }

                setGroupAllDashboardList(groupDashboardList);
                handleGroupDashboardTemplatesList(groupDashboardListItems)

                if(userTemplateList.length > 0 || withGroupDashboard) handleNoDashboard(false)
                else handleNoDashboard(true)
                
                await fetchUsers()
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoading(false);
            }
        };

        loadData();

        return () => {
            isMounted = false;
        };
    }, [dashboardUpdate]);

    const fetchUsers = async () => {
        fetch(`/api/client/showDetails/${selectedClientID}`)
        .then((res) => res.json())
        .then((data) => {
            if (data && !data.error) {
            fetch(`/api/user/getAllRecords/${data.id}`)
                .then((res) => res.json())
                .then((data) => {
                    handleSetClientUsers(data)
                })
                .catch((err) => {
                });
            } else {
            return;
            }
        })  
        .then(() => {
            fetch("/api/accountant/getAllRecords", { credentials: "include" })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    console.log(data.error);
                    return;
                }
                if (!data.error && data) {
                }
            })
            .catch((err) => {
                console.log(err);
            });
        })
        .catch((err) => {
            console.log(err);
        });
    }
    useEffect(() => {
        // console.log(groupList)
        // console.log(groupDashboardList)
    },[groupDashboardList, groupList])

    const fetchGroupDashboardByGroupId = async (group) => {
        const param = { clientId: group.ClientId, groupId: group.id };
    
        const res = await groupDashboards(param);
        const resBI = await groupDashboardsBI(param);
    
        const combinedArray = res.map((item) => ({ ...item, isBI: false, groupName: group.GroupName }))
            .concat(resBI.map((item) => ({ ...item, isBI: true, groupName: group.GroupName })));
    
        combinedArray.sort((a, b) => {
            const dateA = a.createdAt ? new Date(a.createdAt) : null;
            const dateB = b.createdAt ? new Date(b.createdAt) : null;
            if (!dateA && dateB) return 1;
            if (dateA && !dateB) return -1;
            return dateB - dateA;
        });
    
        return combinedArray;
    };

    const handleClick = (item, index) => {
        handleSelectTemplate(item, index);
        onToggleShowDashboardOption();
    }
    const onHandleSetFavorite = (index, isPersonalDashboard = true, groupIndex, groupId) => {
        // if(!isPersonalDashboard)
        //     console.log(groupAllDashboardList[index])
        const param = { index, userTemplateList, groupAllDashboardList, from: isPersonalDashboard ? 'personal': 'group', groupIndex, groupId }
        handleAddtoFavorite(param)
    }
    const history = useHistory();
    const toggleTemplateModal = () => {
        // setIsTemplateEdit(false)
        sessionStorage.setItem("selectedTemplatetoEdit", null);
        // setShowTemplateModal(!showTemplateModal)
        return history.push(`/client/templates`);
    }
    return (
        <>
        <div
            style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
                zIndex: 9998
            }}
            onClick={toggleShowDashboardOption}
        />

        <div className="dashboardContainer">
            <div className="dashboardBody">
            <Row>
                {loading ? <CustomSpinner /> : 
                <>
                    <Col lg='6' sm='12'>
                        <h2>Personal Dashboard</h2>
                        {userTemplateList && userTemplateList.length > 0 ? 
                            userTemplateList.map((item, index) => (
                                <div 
                                    key={item.id} 
                                    className="dashboardOption" 
                                    style={selectedDashboardTemplate?.id === item.id ? { color: bgColor, background: '#fafafa' } : {}}
                                >
                                    <div className='dashboardOptionFav' onClick={e => onHandleSetFavorite(index)}>
                                        <i 
                                            style={{ color: !item.isFavorite && '#b0b0b0' }}
                                            className={`${!item.isFavorite ? 'far' : 'fas'} fa-star fa-sm`}>    
                                        </i>
                                    </div>
                                    <div  style={{ display: 'flex', flexDirection: 'row', width: '100%' }} onClick={() => { handleClick(item, index) }}>
                                        <div className="dashboardImg">
                                            <img
                                                alt="..."
                                                className="mr-2"
                                                style={{ height: "25px", width: "25px" }}
                                                src={item.isBI ? require("assets/img/bi.png").default : require("assets/img/etanilogo.png").default}
                                            />
                                        </div>
                                        <div className="dashboardName">{item.name}</div>
                                    </div>
                                </div>
                            ))
                        :
                            <>
                                <div style={{ padding: 10, fontSize: 12 }}>No dashboard Found!</div>
                            </>
                        }
                    </Col>
                    <Col lg='6' sm='12'>
                        <h2>Group Dashboard</h2>
                        {groupAllDashboardList && groupAllDashboardList.map((item, index) => (
                            <div key={item.groupId}>
                            <h5>{item.groupName}</h5>
                            {item.dashboardList && item.dashboardList.length > 0 ?
                            <>
                                {item.dashboardList.map((DashboardItem, dashboardIndex) => (
                                    <div key={`${DashboardItem.id}-${dashboardIndex}`} 
                                    className="dashboardOption" 
                                    onClick={() => {
                                            handleSelectTemplate(DashboardItem, dashboardIndex);
                                            onToggleShowDashboardOption()
                                        }}
                                        style={selectedDashboardTemplate?.id === DashboardItem.id ? {  color: bgColor, background: '#fafafa' } : {}}
                                    >
                                        <div className='dashboardOptionFav' onClick={e => onHandleSetFavorite(dashboardIndex, false, index, item.groupId)}>
                                            <i 
                                                style={{ color: !DashboardItem.isFavorite && '#b0b0b0' }}
                                                className={`${!DashboardItem.isFavorite ? 'far' : 'fas'} fa-star fa-sm`}>    
                                            </i>
                                        </div>
                                        <div className="dashboardImg">
                                            <img
                                                alt="..."
                                                className="mr-2"
                                                style={{ height: "25px", width: "25px" }}
                                                src={DashboardItem.isBI ? require("assets/img/bi.png").default : require("assets/img/etanilogo.png").default}
                                            />
                                        </div>
                                        <div className="dashboardName">{DashboardItem.name}</div>
                                </div>
                                ))}
                            </> 
                            :
                            <>
                                <div style={{ padding: 10, fontSize: 12 }}>No dashboard Found!</div>
                            </>
                            }
                            </div>
                        ))}
                    </Col>
                </>
                }
            </Row>
            </div>
            <div className="dashboardFooter" style={{ color: bgColor }} onClick={toggleTemplateModal}>
                Create Dashboard Templates
            </div>
        </div>
        </>
    );
}

export default DashboardListComponent;
